var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defaultCulture, supportedCultures, ProgressState } from '../../models';
import * as userActions from '../actions/user.action';
export var initialState = function () { return ({
    userProfile: null,
    progressState: ProgressState.NotLoaded,
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case userActions.LOAD_USER_PROFILE: {
            return __assign({}, state, { progressState: ProgressState.Loading });
        }
        case userActions.LOAD_USER_PROFILE_SUCCESS: {
            return {
                userProfile: action.payload,
                progressState: ProgressState.Loaded
            };
        }
        case userActions.LOAD_USER_PROFILE_FAIL: {
            return __assign({}, state, { progressState: ProgressState.Failed });
        }
        case userActions.SET_DEFAULT_LOCATION_SUCCESS: {
            var companyPreferences = {};
            if (state.userProfile && state.userProfile.companyPreferences) {
                companyPreferences = state.userProfile.companyPreferences;
            }
            return __assign({}, state, { userProfile: __assign({}, state.userProfile, { companyPreferences: __assign({}, companyPreferences, { hubLocationId: action.payload }) }) });
        }
    }
    return state;
}
export var getUserProfile = function (state) { return state.userProfile; };
export var getUserProfileLoading = function (state) { return state.progressState === ProgressState.Loading; };
export var getUserProfileLoaded = function (state) { return state.progressState === ProgressState.Loaded; };
export var getProgressState = function (state) { return state.progressState; };
export var getCulture = function (state) {
    var culture = getSelectedCulture(state);
    if (!culture) {
        return null;
    }
    return isSupportedCulture(culture) ? culture : defaultCulture;
};
export var getLanguage = function (state) { return state.userProfile && state.userProfile.userPreferences.language; };
export var getCountry = function (state) { return state.userProfile && state.userProfile.userPreferences.country; };
export var getTimeZone = function (state) { return state.userProfile &&
    state.userProfile.userPreferences.dstTimeZone.timezone_nm; };
export var getDefaultToNewProfiles = function (state) { return state.userProfile && state.userProfile.defaultToNewProfiles; };
export var getIsSymbolEnabled = function (state) { return state.userProfile && state.userProfile.isSymbolEnabled; };
export var getIsAttachmentEnabled = function (state) { return state.userProfile && state.userProfile.isAttachmentEnabled; };
export var getIsSavedLocationEnabled = function (state) { return state.userProfile &&
    state.userProfile.isSavedLocationEnabled; };
export var getIsHubActivityFormEnabled = function (state) { return state.userProfile &&
    state.userProfile.isHubActivityFormEnabled; };
export var getDefaultLocation = function (state) {
    return state.userProfile && state.userProfile.companyPreferences && state.userProfile.companyPreferences.hubLocationId
        || null;
};
export var getCdfAdfReadOnlyMode = function (state) {
    return state.userProfile && state.userProfile.companyPreferences
        && state.userProfile.companyPreferences.cdfAdfReadOnlyMode;
};
export var getIs12HourFormat = function (state) {
    var culture = getSelectedCulture(state);
    if (!culture) {
        return null;
    }
    return isSupportedCulture(culture)
        ? state.userProfile.userPreferences.shortTimePattern.indexOf('h') !== -1
        : true;
};
function getSelectedCulture(state) {
    return state.userProfile && state.userProfile.userPreferences.culture;
}
function isSupportedCulture(culture) {
    return supportedCultures.find(function (l) { return culture.startsWith(l); });
}
