import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUser from '../reducers/user.reducer';

export const getUserProfileState = createFeatureSelector<fromUser.State>('userProfile');

export const getUserProfile = createSelector(getUserProfileState, fromUser.getUserProfile);

export const getUserProfileProgressState = createSelector(
  getUserProfileState,
  fromUser.getProgressState
);

export const getUserProfileLoading = createSelector(
  getUserProfileState,
  fromUser.getUserProfileLoading
);

export const getUserProfileLoaded = createSelector(
  getUserProfileState,
  fromUser.getUserProfileLoaded
);

export const getIs12HourFormat = createSelector(getUserProfileState, fromUser.getIs12HourFormat);

export const getCulture = createSelector(getUserProfileState, fromUser.getCulture);

export const getCountry = createSelector(getUserProfileState, fromUser.getCountry);

export const getLanguage = createSelector(getUserProfileState, fromUser.getLanguage);

export const getDefaultToNewProfiles = createSelector(getUserProfileState, fromUser.getDefaultToNewProfiles);

export const getIsSymbolEnabled = createSelector(getUserProfileState, fromUser.getIsSymbolEnabled);

export const getIsAttachmentEnabled = createSelector(getUserProfileState, fromUser.getIsAttachmentEnabled);

export const getIsSavedLocationEnabled = createSelector(getUserProfileState, fromUser.getIsSavedLocationEnabled);

export const getIsHubActivityFormEnabled = createSelector(getUserProfileState, fromUser.getIsHubActivityFormEnabled);

export const getTimeZone = createSelector(getUserProfileState, fromUser.getTimeZone);

export const getDefaultLocation = createSelector(getUserProfileState, fromUser.getDefaultLocation);

export const getCdfAdfReadOnlyMode = createSelector(getUserProfileState, fromUser.getCdfAdfReadOnlyMode);
